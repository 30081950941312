import tiktokLogo from "images/Channels/SocialCommerce/tiktok_logo.svg"
import facebookLogo from "images/Channels/SocialCommerce/facebook_logo.svg"
import { useFormatter } from "helpers/i18n"
import "./Partner.scss"

export default function Partner() {
  const { __ } = useFormatter()
  return (
    <>
      <div className="nvblazvhzw">
        <div className="section-title ddkvjzajsu">
          {__({
            defaultMessage: "Our Social Commerce Partners",
          })}
        </div>
        <div className="tpmiegyyeu">
          <div className="shorcuikvq">
            <img src={facebookLogo} alt="" className="efldewkbld" />
          </div>
          <div className="shorcuikvq">
            <img src={tiktokLogo} alt="" className="efldewkbld" />
          </div>
        </div>
        <div className="wraper">
          <div className="kdkeacztjw">
            {__({
              defaultMessage:
                "A breakthrough end-to-end social commerce solution to efficiently customize and optimize on shoppers’ needs.",
            })}
          </div>
        </div>
      </div>
    </>
  )
}
